import APICONFIGS from '../admin-api-configs.json';
import { UrlHelpers, authHeader, customFetch, jsonHeader } from "../../helpers";

export const vocabularyService = {
  getVocabularies,
  getVocabularyById,
  create,
  update,
  remove,
}

function getVocabularies(pagingOptions, filterOptions, sortOptions) {
  const requestOptions = {
    method: 'GET',
    cache: 'no-cache',
    headers: authHeader()
  };
  let apiUrl = UrlHelpers.combine(process.env.REACT_APP_BASEURL, APICONFIGS.Vocabularies);
  let queryString = [pagingOptions, sortOptions].map(r => UrlHelpers.toQueryString(r))
  queryString.push(UrlHelpers.arrayObjectToQueryString(filterOptions, 'filters'));
  queryString = queryString.filter(r => !!r).join("&");
  apiUrl = UrlHelpers.combine(apiUrl, "?" + queryString)

  return customFetch(apiUrl, requestOptions)
    .then(res => res);
}

function getVocabularyById(id) {
  let requestOptions = {
    method: 'GET',
    cache: 'no-cache',
    headers: authHeader()
  };
  let apiUrl = UrlHelpers.combine(process.env.REACT_APP_BASEURL, APICONFIGS.Vocabularies, id);
  return customFetch(apiUrl, requestOptions)
    .then(res => res);
}

function create(model) {
  let requestOptions = {
    method: 'POST',
    cache: 'no-cache',
    headers: authHeader(jsonHeader()),
    body: JSON.stringify(model)
  };
  let apiUrl = UrlHelpers.combine(process.env.REACT_APP_BASEURL, APICONFIGS.Vocabularies);
  return customFetch(apiUrl, requestOptions)
    .then(res => res);
}

function update(model) {
  let requestOptions = {
    method: 'PUT',
    cache: 'no-cache',
    headers: authHeader(jsonHeader()),
    body: JSON.stringify(model)
  };
  let apiUrl = UrlHelpers.combine(process.env.REACT_APP_BASEURL, APICONFIGS.Vocabularies);
  return customFetch(apiUrl, requestOptions)
    .then(res => res);
}

function remove(id) {
  let requestOptions = {
    method: 'DELETE',
    cache: 'no-cache',
    headers: authHeader()
  };
  let apiUrl = UrlHelpers.combine(process.env.REACT_APP_BASEURL, APICONFIGS.Vocabularies);
  apiUrl = UrlHelpers.combine(apiUrl, `${id}`);
  return customFetch(`${apiUrl}`, requestOptions)
    .then(res => res);


}